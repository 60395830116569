<template>
  <v-card>
      <v-card-title>
          Unable to find <span class="info--text px-2"> {{ user.employeeFullName }}'s </span>  3-4 ID
      </v-card-title>
      <v-card-text>
        This application is not able to schedule users without a 3-4 ID at this time. 
      </v-card-text>
      <v-card-actions>
          <v-btn color="info" style="width: 100%" x-large @click="closeme"> Close </v-btn>
      </v-card-actions>
  </v-card>
</template>

<script>
export default {
    props: {
        user: {
            type: Object,
            default: () => {
                employeeFullName: 'User'
            },
            required: true
        },
    },
    methods: {
        closeme() {
            this.$emit('closeInvalidUser')
        }
    },
}
</script>

<style>

</style>